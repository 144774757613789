import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function AtualizarImagens() {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");

  const atualizarImagens = async () => {
    setLoading(true);
    setError("");
    setResponseMessage("");

    try {
      const res = await axios.post(`${baseURL}/atualizarImagens`);
      setResponseMessage(
        `${res.data.message} Total de registros alterados: ${res.data.rowsAffected}`
      );
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao atualizar as imagens.";
      setError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>Atualizar Imagens</h2>
      <button onClick={atualizarImagens} disabled={loading}>
        {loading ? "Atualizando..." : "Atualizar Todas as Imagens"}
      </button>

      {loading && <p>Atualizando imagens, por favor aguarde...</p>}
      {responseMessage && <p style={{ color: "green" }}>{responseMessage}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default AtualizarImagens;
