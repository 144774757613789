import React, { useState } from "react";
import axios from "axios";
import minhaImagem from "../logo-mariah.png";
import logo_mariah_ia from "../mariah-ia-logo.png";
import "../Spinner.css";

const baseURL = "http://89.116.214.151:3000/v1";

function EnviarImagens() {
  const [responseMessage, setResponseMessage] = useState(""); // Para mensagens amigáveis
  const [uploadedImages, setUploadedImages] = useState([]); // Para armazenar as imagens enviadas
  const [nomeLaudo, setNomeLaudo] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({ total: 0, completed: 0 });

  const validarNomeArquivo = (nomeArquivo) => {
    const regexInvalido = /^\d+\s*\(\d+\)$/;
    return !regexInvalido.test(nomeArquivo.replace(/\.\w+$/, ""));
  };

  // Função para extrair o nome do ambiente e renomear o arquivo
  const extrairAmbienteENome = (nomeArquivo) => {
    // Regex para capturar o padrão correto
    const regex = /^(\d+\s*\(\d+\))\s*(.*?)(\.\w+)$/;

    const match = nomeArquivo.match(regex);
    if (match) {
      return {
        novoNome: match[1].trim() + match[3], // Ex.: "1 (65).JPEG"
        ambiente: match[2].trim() || "Desconhecido", // O restante será o ambiente ou "Desconhecido"
      };
    }

    // Regex para tratar casos colados, como "SalaDeEstar1 (65).JPEG"
    const coladoRegex = /^(.*?[a-zA-Z]+)(\d+\s*\(\d+\))(\.\w+)$/;
    const coladoMatch = nomeArquivo.match(coladoRegex);
    if (coladoMatch) {
      return {
        novoNome: coladoMatch[2].trim() + coladoMatch[3], // Ex.: "1 (65).JPEG"
        ambiente: coladoMatch[1].trim() || "Desconhecido", // O ambiente será o texto antes do número
      };
    }

    // Regex alternativo para tratar casos invertidos: "Banheiro 1 (65).JPEG"
    const reverseRegex = /^(.*?)\s*(\d+\s*\(\d+\))(\.\w+)$/;
    const reverseMatch = nomeArquivo.match(reverseRegex);
    if (reverseMatch) {
      return {
        novoNome: reverseMatch[2].trim() + reverseMatch[3], // Ex.: "1 (65).JPEG"
        ambiente: reverseMatch[1].trim() || "Desconhecido", // O texto antes será o ambiente
      };
    }

    // Caso nada seja identificado, retorna o nome original como "Desconhecido"
    return { novoNome: nomeArquivo, ambiente: "Desconhecido" };
  };

  const enviarImagens = async (event) => {
    const files = Array.from(event.target.files);

    if (!nomeLaudo) {
      setResponseMessage("Por favor, preencha o nome do laudo.");
      return;
    }

    setProgress({ total: files.length, completed: 0 });
    setLoading(true);
    setResponseMessage("");

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { novoNome, ambiente } = extrairAmbienteENome(file.name); // Extrai o nome e o ambiente
        const fileFormData = new FormData();

        // Criar um novo arquivo com o nome alterado para enviar
        const novoArquivo = new File([file], novoNome, { type: file.type });

        fileFormData.append("nomeLaudo", nomeLaudo);
        fileFormData.append("ambiente", ambiente); // Adicionar o ambiente extraído
        fileFormData.append("imagens", novoArquivo); // Adicionar o arquivo renomeado

        console.log(`Enviando: ${novoNome}, Ambiente: ${ambiente}`);

        await axios.post(`${baseURL}/enviarImagens`, fileFormData);

        // Atualiza o progresso no spinner
        setProgress((prev) => ({
          total: prev.total,
          completed: prev.completed + 1,
        }));

        // Atualiza a lista de imagens enviadas
        setUploadedImages((prev) => [...prev, file.name]);
      }

      setResponseMessage("Imagens enviadas com sucesso!");
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao enviar as imagens.";
      setResponseMessage(errorMsg);
    } finally {
      setLoading(false);

      setTimeout(() => {
        window.location.reload();
      }, 5000); // Delay de 1 segundo para exibir a mensagem de sucesso antes do reload
    }
  };

  return (
    <section>
      <div className="MariahGeral">
        <div className="title-and-image">
          <img
            className="logo_mariah_ia"
            src={logo_mariah_ia}
            alt="Logo Mariah IA"
          />
          <img
            src={minhaImagem}
            alt="Descrição da imagem"
            className="mariah-image"
          />
        </div>

        <h2>Upload de Fotos</h2>

        <input
          type="text"
          value={nomeLaudo}
          onChange={(e) => setNomeLaudo(e.target.value)}
          placeholder="Nome do Laudo"
          disabled={loading}
        />

        <input
          type="file"
          multiple
          onChange={enviarImagens}
          disabled={loading}
        />
      </div>

      {loading && (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>
            Enviando imagens: {progress.completed}/{progress.total}
          </p>
        </div>
      )}

      {!loading && responseMessage && <p>{responseMessage}</p>}

      {!loading && uploadedImages.length > 0 && (
        <div>
          <h4>Imagens Enviadas:</h4>
          <ul>
            {uploadedImages.map((imageName, index) => (
              <li key={index}>{imageName}</li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}

export default EnviarImagens;
