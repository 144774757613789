import React, { useState } from "react";
import axios from "axios";

const GerarLaudo = () => {
  const [formData, setFormData] = useState({
    uso: "",
    tipo: "",
    realizada: "",
    tipoVistoria: "",
    endereco: "",
    cep: "",
    tamanho: "",
    mobilia: "",
    energia: "",
    agua: "",
    ambientes: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      uso,
      tipo,
      realizada,
      tipoVistoria,
      endereco,
      cep,
      tamanho,
      mobilia,
      energia,
      agua,
      ambientes,
    } = formData;

    if (
      !uso ||
      !tipo ||
      !realizada ||
      !tipoVistoria ||
      !endereco ||
      !cep ||
      !tamanho ||
      !mobilia ||
      !energia ||
      !agua ||
      !ambientes
    ) {
      setResponseMessage("Todos os campos são obrigatórios.");
      return;
    }

    setLoading(true);
    setResponseMessage("");

    const requestBody = {
      ...formData,
      ambientes: ambientes.split(",").map((amb) => amb.trim()), // Divide os ambientes por vírgula
    };

    try {
      const response = await axios.post(
        "http://89.116.214.151:3000/v1/gerarLaudo",
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      setResponseMessage("Laudo gerado e enviado com sucesso!");
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao gerar o laudo.";
      setResponseMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Gerar Laudo (Primeira e última página)</h2>
      <form onSubmit={handleSubmit}>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Uso:</label> <br />
          <input
            type="text"
            name="uso"
            value={formData.uso}
            onChange={handleInputChange}
            placeholder="Ex: Residencial"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Tipo:</label>
          <br />
          <input
            type="text"
            name="tipo"
            value={formData.tipo}
            onChange={handleInputChange}
            placeholder="Ex: Apartamento"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Realizada:</label>
          <br />
          <input
            type="text"
            name="realizada"
            value={formData.realizada}
            onChange={handleInputChange}
            placeholder="Ex: 16/10/2024 - 9h"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Tipo de Vistoria:</label>
          <br />
          <input
            type="text"
            name="tipoVistoria"
            value={formData.tipoVistoria}
            onChange={handleInputChange}
            placeholder="Ex: Entrada"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Endereço:</label>
          <br />
          <input
            type="text"
            name="endereco"
            value={formData.endereco}
            onChange={handleInputChange}
            placeholder="Ex: R. David Ben Gurion, 955 - Apto 81 - Torre 1 - São Paulo/SP"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>CEP:</label>
          <br />
          <input
            type="text"
            name="cep"
            value={formData.cep}
            onChange={handleInputChange}
            placeholder="Ex: 05634-001"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Tamanho:</label>
          <br />
          <input
            type="text"
            name="tamanho"
            value={formData.tamanho}
            onChange={handleInputChange}
            placeholder="Ex: 141 m²"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Mobília:</label>
          <br />
          <input
            type="text"
            name="mobilia"
            value={formData.mobilia}
            onChange={handleInputChange}
            placeholder="Ex: Sim"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Energia:</label>
          <br />
          <input
            type="text"
            name="energia"
            value={formData.energia}
            onChange={handleInputChange}
            placeholder="Ex: Sim"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Água:</label>
          <br />
          <input
            type="text"
            name="agua"
            value={formData.agua}
            onChange={handleInputChange}
            placeholder="Ex: Sim"
          />
        </div>
        <div className="campo-input-gerar-primeira-e-ultima-pagina">
          <label>Ambientes (separe por vírgula):</label>
          <br />
          <textarea
            name="ambientes"
            value={formData.ambientes}
            onChange={handleInputChange}
            placeholder="Ex: Área Externa, Sala de Estar, Varanda"
            className="campo-de-ambientes-gerar-laudo-primeira-e-ultima-pagina"
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Gerando Laudo..." : "Gerar Laudo"}
        </button>
      </form>
      {responseMessage && <div className="response">{responseMessage}</div>}
    </div>
  );
};

export default GerarLaudo;
