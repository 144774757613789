import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000";

function GerenciadorDeImagens() {
  const [laudos, setLaudos] = useState([]); // Estado para armazenar os laudos disponíveis
  const [selectedLaudo, setSelectedLaudo] = useState(""); // Estado para o laudo selecionado
  const [ambientes, setAmbientes] = useState([]); // Estado para os ambientes do laudo selecionado
  const [selectedAmbiente, setSelectedAmbiente] = useState(""); // Estado para o ambiente selecionado
  const [imagens, setImagens] = useState([]); // Estado para armazenar as imagens
  const [pdfs, setPdfs] = useState([]); // Estado para armazenar os PDFs da pasta
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado de erro
  const [successMessage, setSuccessMessage] = useState(""); // Estado para mensagem de sucesso

  // Função para listar laudos disponíveis
  const listarLaudos = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/laudosDisponiveis`);
      if (res.data.laudos && res.data.laudos.length > 0) {
        setLaudos(res.data.laudos);
        setSelectedLaudo("");
        setAmbientes([]);
        setSelectedAmbiente("");
        setImagens([]);
      } else {
        setError("Nenhum laudo disponível para listar imagens.");
      }
    } catch (error) {
      setError("Erro ao buscar laudos disponíveis.");
    } finally {
      setLoading(false);
    }
  };

  // Função para selecionar um laudo e carregar os ambientes
  const handleLaudoChange = (laudoId) => {
    const laudo = laudos.find((l) => l.laudo_id === parseInt(laudoId, 10));
    if (laudo) {
      setSelectedLaudo(laudo.laudo_nome);
      setAmbientes(laudo.ambientes || []);
      setSelectedAmbiente("");
      setImagens([]);
    }
  };

  // Função para listar imagens com base no laudo e ambiente
  const listarImagens = async () => {
    if (!selectedLaudo || !selectedAmbiente) {
      alert("Por favor, selecione um laudo e um ambiente.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/listarImagens`, {
        params: { nomeLaudo: selectedLaudo, nomeAmbiente: selectedAmbiente },
      });
      const imagens = res.data.imagens || [];
      if (imagens.length > 0) {
        setImagens(imagens);
      } else {
        setError(
          "Nenhuma imagem encontrada para o laudo e ambiente selecionados."
        );
      }
    } catch (error) {
      setError("Erro ao listar imagens.");
    } finally {
      setLoading(false);
    }
  };

  // Função para visualizar PDFs disponíveis
  const visualizarPdfs = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/listarPDFsPesados`);
      if (res.data.files && res.data.files.length > 0) {
        setPdfs(res.data.files);
      } else {
        setError("Não existe nenhum laudo disponível para download.");
      }
    } catch (error) {
      setError("Não existe nenhum laudo disponível para download.");
    } finally {
      setLoading(false);
    }
  };

  // Função para deletar PDFs
  const deletarPdfs = async () => {
    setLoading(true);
    setError("");
    setSuccessMessage("");
    try {
      const res = await axios.delete(`${baseURL}/v1/deletarPDFsPesados`);
      setSuccessMessage(res.data.message);
      setPdfs([]); // Limpar a lista de PDFs
    } catch (error) {
      setError("Erro ao deletar PDFs.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={listarLaudos} disabled={loading}>
        {loading ? "Carregando..." : "Listar Imagens"}
      </button>

      <button
        onClick={visualizarPdfs}
        disabled={loading}
        style={{ marginLeft: "1rem" }}
      >
        {loading ? "Carregando..." : "Visualizar PDFs"}
      </button>

      <button
        onClick={deletarPdfs}
        disabled={loading || pdfs.length === 0}
        style={{ marginLeft: "1rem" }}
      >
        {loading ? "Carregando..." : "Deletar PDFs"}
      </button>

      {successMessage && <p className="success">{successMessage}</p>}
      {error && <p className="error">{error}</p>}

      {laudos.length > 0 && (
        <div
          className="selectors-container"
          style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}
        >
          <div className="laudo-selector">
            <h3>Selecione um laudo:</h3>
            <select
              value={selectedLaudo}
              onChange={(e) => handleLaudoChange(e.target.value)}
            >
              <option value="">Escolha um laudo</option>
              {laudos.map((laudo) => (
                <option key={laudo.laudo_id} value={laudo.laudo_id}>
                  {laudo.laudo_nome}
                </option>
              ))}
            </select>
          </div>

          {ambientes.length > 0 && (
            <div className="ambiente-selector">
              <h3>Selecione um ambiente:</h3>
              <select
                value={selectedAmbiente}
                onChange={(e) => setSelectedAmbiente(e.target.value)}
              >
                <option value="">Escolha um ambiente</option>
                {ambientes.map((ambiente, index) => (
                  <option key={index} value={ambiente}>
                    {ambiente}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      {selectedLaudo && selectedAmbiente && (
        <button
          onClick={listarImagens}
          disabled={loading}
          style={{ marginTop: "1rem" }}
        >
          {loading ? "Carregando..." : "Listar Imagens"}
        </button>
      )}

      <div className="image-list">
        {imagens.map((imagem, index) => (
          <div key={index} className="image-item">
            <p>{imagem.nome}</p>
            <img src={imagem.thumbnail} alt={imagem.nome} />
          </div>
        ))}
      </div>

      {pdfs.length > 0 && (
        <div className="pdf-list" style={{ marginTop: "2rem" }}>
          <h3>Laudos Disponíveis para Download:</h3>
          <ul>
            {pdfs.map((pdf, index) => (
              <li key={index}>
                <a
                  href={pdf.downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pdf.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default GerenciadorDeImagens;
