import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function Laudos() {
  const [laudos, setLaudos] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado para erros
  const [deleteMessage, setDeleteMessage] = useState(""); // Mensagem de remoção

  const fetchLaudosDisponiveis = async () => {
    setLoading(true); // Inicia o estado de carregamento
    setError(""); // Limpa o erro antes da nova requisição

    try {
      const res = await axios.get(`${baseURL}/laudosDisponiveis`);
      setLaudos(res.data.laudos); // Define os laudos retornados pela API
    } catch (error) {
      console.error("Erro ao buscar os laudos disponíveis:", error);
      setError(
        error.response?.data?.message || "Erro ao buscar os laudos disponíveis"
      );
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  const removerLaudo = async (nomeLaudo) => {
    setLoading(true); // Inicia o estado de carregamento
    setDeleteMessage(""); // Limpa a mensagem anterior
    setError(""); // Limpa o erro

    try {
      const res = await axios.delete(`${baseURL}/removerLaudo`, {
        data: { nomeLaudo },
      });
      setDeleteMessage(res.data.message); // Exibe a mensagem de sucesso
      fetchLaudosDisponiveis(); // Atualiza a lista de laudos após remoção
    } catch (error) {
      console.error("Erro ao remover o laudo:", error);
      setError(error.response?.data?.message || "Erro ao remover o laudo");
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Laudos</h2>
      <button onClick={fetchLaudosDisponiveis} disabled={loading}>
        {loading ? "Carregando..." : "Carregar Laudos"}
      </button>

      {loading && <p>Carregando laudos...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {deleteMessage && <p style={{ color: "green" }}>{deleteMessage}</p>}

      {!loading && !error && laudos.length === 0 && <p></p>}
      <ul>
        {laudos.map((laudo) => (
          <li key={laudo.laudo_id}>
            {laudo.laudo_nome} {/* Usar laudo_nome para exibir o nome */}
            <button
              onClick={() => removerLaudo(laudo.laudo_nome)} // Usar laudo_nome ao remover
              disabled={loading}
            >
              {loading ? "Removendo..." : "Remover"}
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Laudos;
